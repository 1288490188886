import { errorMessageStore } from '../stores/ErrorMessageStore';

import { FileNotFoundError } from './ErrorHandler';
import { fetchData, getCloudDownloadUrl, getGUID, getPhpApiBaseUrl, getUrlParamValue, isOWDSlink, isSharepoint } from './utils';

import { isBrowserARAvailable } from '@egr/xbox/AR/Availability'
import { getUUID } from '@egr/xbox/utils/Helper';
import { android, ios } from '@egr/xbox/utils/UserAgent';

import { joinPath } from '@easterngraphics/wcf/modules/utils/string';
export type FileExtension = '' | 'jpg' | 'png' | 'epan' | 'eimg' | 'pec' | 'glb' | 'gltf' | 'usdz' | 'iar';
const fileExtensions: Array<FileExtension> = ['jpg', 'png', 'epan', 'eimg', 'pec', 'glb', 'gltf', 'usdz', 'iar'];
export interface UrlInfo {
    extension: FileExtension;
    fileDownloadUrl: string; // http or UrlBlob or data64
    fileUrl: string; // either shared or cloud
    embed: boolean;
    validUntil?: number; // Date.parse()
    arFile?: boolean;
}

let cacheUrlInfo: UrlInfo = { extension: '', fileDownloadUrl: '', fileUrl: '', embed: false };

function getValidDate(valid: string | null): number | undefined {
    return valid && valid?.length === 8
    ? Date.parse(valid.slice(0, 4) + '-' + valid.slice(4, 6) + '-' + valid.slice(6, 8))
    : undefined;
}

interface Parameters {
    edit: string | null;
    embed: string | null;
    url: string | null;
    view: string | null;
    glb: string | null;
    usdz: string | null;
}

function getParameters(href: string): Parameters {
    const glbValue = getUrlParamValue(href, 'glb');
    const usdzValue = getUrlParamValue(href, 'usdz');
    return {
        edit: getUrlParamValue(href, 'edit'),
        embed: getUrlParamValue(href, 'embed'),
        url: getUrlParamValue(href, 'url'),
        view: getUrlParamValue(href, 'view'),
        glb: glbValue ? atob(glbValue) : null,
        usdz: usdzValue ? atob(usdzValue) : null
    };
}

function hasValidParameters(parameters: Parameters): boolean {
    return (parameters.edit ?? parameters.embed ?? parameters.url ?? parameters.view ?? parameters.glb ?? parameters.usdz) != null;
}

function getFileUrl(parameters: Parameters, href: string): string {
    return parameters.glb ?? parameters.usdz ?? parameters.edit ?? parameters.embed ?? parameters.url ?? parameters.view ?? href;
}

function shouldSaveOnImpressServer(urlInfo: UrlInfo): boolean {
    return (urlInfo.fileDownloadUrl !== '' && (android || ios)) && (
        urlInfo.extension === 'iar' ||
        urlInfo.extension === 'gltf' ||
        urlInfo.extension === 'glb' ||
        urlInfo.extension === 'usdz');
}

export async function getUrlInfo(href: string = '', forceUpdate: boolean = false): Promise<UrlInfo> {
    // url?:string
    if (cacheUrlInfo.extension !== '' && !forceUpdate) {
        // || cacheUrlInfo.embed !!!!
        return cacheUrlInfo;
    }

    const url = href.replace(/&amp;/g, '&');
    const parameters = getParameters(url);

    let fileUrl: string = getFileUrl(parameters, url);

    const clouds: Array<string> = [
        // usually do not end with the file extension
        'owds.pcon-solutions.com/api/',
        'owds.egr.eu/api/',
        'dropbox.com/',
        '1drv.ms',
        'onedrive.live.com',
        'drive.google.com/',
        '.sharepoint.com/',
    ];
    const exts: Array<string> = [
        // require getUrlInImpressServer
        '.iar', // Don't:  , '.glb', '.gltf', '.usdz' (self contained in zip)
    ];

    if (
        exts.some((ext) => fileUrl.endsWith(ext)) || // href --> fileUrl
        clouds.some((cloudName) => fileUrl.includes(cloudName)) ||
        isEgrFileService_AR_MultiFileLink(href) // query parameters glb=...&usdz=...
    ) {
        const parameterValid: string | null = getUrlParamValue(href, 'valid');
        const dateValid = getValidDate(parameterValid);
        if (parameters.glb && parameters.usdz) {
            // Is EGR service glb=...&usdz=...
            cacheUrlInfo = {
                embed: true,
                extension: android ? 'glb' : 'usdz', // triggers the viewer
                fileDownloadUrl: isBrowserARAvailable ? (android ? parameters.glb : parameters.usdz) : href,
                fileUrl: href, // Required for ClipboardDialog opening // ''
                validUntil: dateValid,
                arFile: true
            };
            return cacheUrlInfo;
        }

        const accessPoint: string = isSharepoint(decodeURIComponent(fileUrl), 'getUrlInfo 1') ? 'php/getsptext.php?url=' : 'php/resolveUrl.php?url=';
        fileUrl = getCloudDownloadUrl(fileUrl);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const json: any = await fetchData(joinPath(getPhpApiBaseUrl(), accessPoint) + encodeURIComponent(fileUrl), { method: 'GET' }, 'Json');

        if (!json) {
            return cacheUrlInfo;
        }

        cacheUrlInfo = {
            embed: parameters.embed ? true : false,
            extension: json.extension,
            fileDownloadUrl: isSharepoint(decodeURIComponent(fileUrl), 'getUrlInfo 2') ? json.location : json.downloadUrl,
            fileUrl: href, // Required for ClipboardDialog opening // ''
            validUntil: dateValid,
            arFile: parameters.glb != null
        };

        if (shouldSaveOnImpressServer(cacheUrlInfo)) {
            cacheUrlInfo.fileDownloadUrl = await getUrlInImpressServer(cacheUrlInfo.fileDownloadUrl, json.extension);
        }
        console.log(cacheUrlInfo.fileDownloadUrl);
    } else {
        let ext: FileExtension = '';
        fileExtensions.forEach((e) => {
            if (fileUrl.endsWith('.' + e)) {
                ext = e;
            }
        });

        cacheUrlInfo = {
            embed: parameters.embed ? true : false,
            extension: ext,
            fileDownloadUrl: fileUrl,
            fileUrl: href, // Required for ClipboardDialog opening // ''
            arFile: parameters.glb != null
        };
    }
    // if file has no valid extension
    if (cacheUrlInfo.extension === '' && hasValidParameters(parameters)) {
        errorMessageStore.open(new FileNotFoundError(isOWDSlink(location.href)));
    }
    return cacheUrlInfo;
}
export function isEgrFileService_AR_MultiFileLink(url: string): boolean {
    url = url.toLowerCase();
    return url.includes('glb=') && url.includes('usdz=');
}

async function getUrlInImpressServer(url: string, ext: string): Promise<string> {
    const parsedUrl: URL = new URL(cacheUrlInfo.fileDownloadUrl);
    const init: RequestInit = {
        method: 'GET'
    };
    const name: string = getGUID();
    if (ext === 'iar') {

        const apiurl: string = getPhpApiBaseUrl() + 'php/iar.php?url=' + encodeURIComponent(url) + '&name=' + encodeURIComponent(name);

        await fetchData(apiurl, init, 'Json');
        const fileName: string = android ? 'impress.gltf' : 'impress.usdz';
        const ret: string = getPhpApiBaseUrl() + 'php/temp/' + name + '/' + fileName;

        return ret;
    }
    if (parsedUrl.hostname.endsWith('google.com')) {
        const name: string = getUUID();
        const params: URLSearchParams = new URLSearchParams({
            url: decodeURIComponent(cacheUrlInfo.fileDownloadUrl),
            ext: cacheUrlInfo.extension,
            name
        });
        await fetch(`${getPhpApiBaseUrl()}php/proxy.php?${params.toString()}`);
        url = `${getPhpApiBaseUrl()}php/temp/${name}.${cacheUrlInfo.extension}`;
    }

    return url;
}
