import { ThemeProvider } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Header from './components/AppBar/Header';
import ClipboardDialog from './components/ClipboardDialog';
import ClipboardDialogAR from './components/ClipboardDialogAR';
import { DefaultDialogs } from './components/DefaultDialogs';
import { DialogImprint } from './components/DialogImprint';
import ErrorMessage from './components/ErrorMessage';
import FAQ from './components/FAQ';
import { DelayedLoadingTile } from './components/Progress';
import SnapshotDialog from './components/SnapshotDialog';
import Toast from './components/Toast';
import UrlComp from './components/UrlBar';
import { defaultCookieUserSettingsManager, getAcceptedCookieUserSetting } from './components/cookieDialog/CookieUserSettingManager';
import DeviceInfo from './components/debug/DeviceInfo';
import Footer from './components/footer/Footer';
import { appStore } from './stores/AppStore';
import theme from './styles/theme';
import './styles/App.css';
import { TelemetrySetCustomDimension1 } from './utils/Telemetry';
import { isEgrFileService_AR_MultiFileLink } from './utils/UrlInfo';
import { isStandAloneApp } from './utils/ViewerUtilities';
import { ViewerContainer } from './viewers/ViewerContainer';

import { ErrorBoundary } from '@egr/xbox/egr-gui-elements/single-components/ErrorBoundary';
import { getEnvBoolean } from '@egr/xbox/utils/ReactScriptHelper';
import { android, inIframe } from '@egr/xbox/utils/UserAgent';
import { setupSentry } from '@egr/xbox/utils/errors/Error';

export const LanguageDefault: string = 'en';

const DEBUG_MODE = getEnvBoolean('DEBUG', false);

const App: React.FC = observer(() => {
    React.useEffect(() => {
        defaultCookieUserSettingsManager.initialize();
        onResize();
        TelemetrySetCustomDimension1(embed ? 'embedded' : 'direct call');
        if (!inIframe() && getAcceptedCookieUserSetting().required === false) {
            if (defaultCookieUserSettingsManager != null) {
                void defaultCookieUserSettingsManager.showDialog(false);
            }
        }

        setupSentry();
        addEvents();

        return () => {
            removeEvents();
        };
    }, []);

    const { urlInfo, embed } = appStore.state;
    const displayDesktopArDialog: boolean = urlInfo != null && isEgrFileService_AR_MultiFileLink(urlInfo.fileUrl) || urlInfo?.extension === 'iar';

    if (embed) {
        return (
            <ErrorBoundary silent={false} color="default">
                <ThemeProvider theme={theme}>
                    <DelayedLoadingTile />
                    <ErrorMessage />
                    <ViewerContainer />
                    {displayDesktopArDialog ? <ClipboardDialogAR /> : <ClipboardDialog />}
                    <DefaultDialogs />
                    {DEBUG_MODE && <DeviceInfo />}
                </ThemeProvider>
            </ErrorBoundary>
        );
    }

    return (
        <ErrorBoundary silent={false} color="default">
            <ThemeProvider theme={theme}>
                <Header />
                <div className="main" style={{ zIndex: 0 }}>
                    <UrlComp />
                    <DelayedLoadingTile />
                    <ErrorMessage />
                    <ViewerContainer />
                    <FAQ />
                    <DialogImprint />
                </div>
                <Footer />
                {displayDesktopArDialog ? <ClipboardDialogAR /> : <ClipboardDialog />}
                <SnapshotDialog />
                <Toast />
                <DefaultDialogs />
                {DEBUG_MODE && <DeviceInfo />}
            </ThemeProvider>
        </ErrorBoundary>
    );
});

function onResize(): void {
    const root: HTMLElement | null = document.getElementById('root');
    if (root !== null) {
        const w: number = window.innerWidth;
        const h: number = window.innerHeight;
        const width: string = android ? '100%' : w + 'px';
        const height: string = android ? 'vh' : h + 'px';
        // Android: 100% is short when the address bar is hidden...

        root.style.height = height;
        root.style.maxHeight = height;

        root.style.width = width;
        root.style.maxWidth = width;

        if (isStandAloneApp()) {
            root.style.position = 'fixed';
        }
    }
}
function handler() {
    window.setTimeout(
        () => {
            window.dispatchEvent(new Event('customfullscreenchange'));
            window.dispatchEvent(new UIEvent('resize')); // triggers bubbles repositioning
        },
        500 // waits for UI to be redrawn
    );
}

function addEvents() {
    if (document.addEventListener) {
        window.addEventListener('resize', onResize);
        window.addEventListener('orientationchange', onResize);

        document.addEventListener('fullscreenchange', handler, false);
        document.addEventListener('mozfullscreenchange', handler, false);
        document.addEventListener('MSFullscreenChange', handler, false);
        document.addEventListener('webkitfullscreenchange', handler, false);
    }
}
function removeEvents() {
    window.removeEventListener('resize', onResize);
    window.removeEventListener('orientationchange', onResize);

    document.removeEventListener('fullscreenchange', handler, false);
    document.removeEventListener('mozfullscreenchange', handler, false);
    document.removeEventListener('MSFullscreenChange', handler, false);
    document.removeEventListener('webkitfullscreenchange', handler, false);
}

export default App;
