import type { WithTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {appStore} from '../../stores/AppStore';
import { isPhoneNotchAtLeft } from '../../utils/ViewerUtilities';
import { dispatchCustomEvent_DialogMessage } from '../DialogImprint';
import { defaultCookieUserSettingsManager } from '../cookieDialog/CookieUserSettingManager';

import FooterLink from './FooterLink';

import { VersionLabel } from '@egr/xbox/utils/Version';

const versionStyle: React.CSSProperties = {
    position: 'absolute',
    right: '20px',
    color: 'rgb(134,134,134)',
    fontSize: '11px',

};

const Footer: React.FC<WithTranslation> = ({ t }) => {
    const [, setRenderTrigger] = useState(0);
    const updateComponentUI = () => {
        setRenderTrigger(prev => prev + 1);
    };

    useEffect(() => {
        window.addEventListener('resize', updateComponentUI);
        window.addEventListener('orientationchange', updateComponentUI);
        document.addEventListener('customfullscreenchange', updateComponentUI);

        return () => {
            window.removeEventListener('resize', updateComponentUI);
            window.removeEventListener('orientationchange', updateComponentUI);
            document.removeEventListener('customfullscreenchange', updateComponentUI);
        };
    }, []);

    const styleNotchAtLeft: React.CSSProperties = {
        paddingLeft: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sal'), 10) + 'px',
    };
    const safeAreaBottom: number = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab'), 10);
    const styleFooter: React.CSSProperties = {
        paddingBottom: safeAreaBottom,
        width: '100%',
    };
    const styleCombined: React.CSSProperties = Object.assign(styleFooter, (isPhoneNotchAtLeft() ? styleNotchAtLeft : {}));

    const links = [
        { href: `https://www.easterngraphics.com/${appStore.state.language}/`, label: '© EasternGraphics GmbH' },
        { href: '', onClick: openLegalNotice, label: t('Legal notice') },
        { href: '', onClick: openTermsOfUse, label: t('Terms of use') },
        { href: '', onClick: openDataPrivacy, label: t('Data privacy') },
        {
            href: '',
            onClick: (event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                void defaultCookieUserSettingsManager.showDialog();
            },
            label: t('Cookie settings'),
        },
        { href: `https://impress.wiki.pcon-solutions.com/doku.php?id=${appStore.state.language}:start`, label: t('FAQ') },
    ];

    return (
        <footer style={styleCombined}>
            <ul>
                {links.map((link, index) => (
                    <FooterLink
                        key={index}
                        href={link.href}
                        onClick={link.onClick}
                        isLast={index === links.length - 1}
                    >
                        {link.label}
                    </FooterLink>
                ))}
                <li style={versionStyle} data-sentrylabel="footer-versionInfo">
                    {VersionLabel}
                </li>
            </ul>
        </footer>
    );
};

export default withTranslation()(Footer);

async function onClickDisplayLegalInfo(url: string, tdWidth: string) {
    const response: Response = await fetch(url);
    if (response) {
        const html: string = await response.text();
        const parser: DOMParser = new DOMParser();
        const htmlDoc: Document = parser.parseFromString(html, 'text/html');

        const innerHTML: string = htmlDoc.body.innerHTML;

        dispatchCustomEvent_DialogMessage({
            title: 'The title',
            html: innerHTML,
            tdWidth: tdWidth,
        });
    }
}

export async function openLegalNotice(event: React.MouseEvent<HTMLElement>): Promise<void> {
    event.preventDefault(); // url = url.replace('lang=en', 'lang=' + appStore.state.currentLanguage[0]);
    let url: string = 'https://legal-information.pcon-solutions.com/v1/app/en/responsive-iframe/headline+address-information+commercial-information.html';
    url = url.replace('/en/', '/' + appStore.getLanguage[0] + '/');
    await onClickDisplayLegalInfo(url, 'unset');
}
export async function openTermsOfUse(event: React.MouseEvent<HTMLElement>): Promise<void> {
    event.preventDefault();
    let url: string = 'https://legal-information.pcon-solutions.com/v1/app/en/responsive-iframe/headline+terms-of-use.html';
    url = url.replace('/en/', '/' + appStore.getLanguage[0] + '/');
    await onClickDisplayLegalInfo(url, '50%');
}

export async function openDataPrivacy(event: React.MouseEvent<HTMLElement>): Promise<void> {
    event.preventDefault();
    let url: string = 'https://legal-information.pcon-solutions.com/v1/app/en/responsive-iframe/headline+privacy-policy.html';
    url = url.replace('/en/', '/' + appStore.getLanguage[0] + '/');
    await onClickDisplayLegalInfo(url, '50%');
}
