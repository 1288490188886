import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import { appStore } from '../stores/AppStore';
import ClipboardDialogStore from '../stores/ClipboardDialogStore';
import ToastStore from '../stores/ToastStore';
import { TelemetryTrackEvent } from '../utils/Telemetry';
import { deviceSupportsFileExtension, openARLink } from '../viewers/ViewerAR';

import { isBrowserARAvailable } from '@egr/xbox/AR/Availability';
import Dialog from '@egr/xbox/egr-gui-dialogs/utils/DialogOverride';
import { inIframe } from '@egr/xbox/utils/UserAgent';

const ClipboardDialogAR: React.FC = () => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const { showQR, show } = ClipboardDialogStore.state;
  const { urlInfo, language } = appStore.state;
  const copyToClipboard = (): void => {
    if (navigator.clipboard) {
      if (urlInfo != null) {
        void navigator.clipboard.writeText(urlInfo.fileUrl);
        ToastStore.open(t('Copied to clipboard'));
        TelemetryTrackEvent('Embed content');
      }
    }
  };
  if (urlInfo == null) {
    return null;
  }
  const showArOpenButton: boolean =
    showQR &&
    isBrowserARAvailable &&
    deviceSupportsFileExtension(urlInfo.extension);
  const showCloseButton: boolean = showQR && inIframe() ? false : true;
  const validUntil: string = urlInfo.validUntil
    ? new Date(urlInfo.validUntil).toLocaleDateString(language)
    : '';
  const isExpired: boolean = urlInfo.validUntil
    ? urlInfo.validUntil < Date.now()
    : false;
  return (
    <Dialog
      open={show}
      onClose={() => {
        ClipboardDialogStore.close();
      }}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: isSmallScreen ? '100%' : 700,
          margin: 0,
          height: 'content-fit',
          minHeight: 350,
        },
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={5.5}>
          <Box
            sx={{
              backgroundColor: 'rgb(248,248,248)',
              minHeight: 350,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <QRCode
              style={{ maxWidth: '100%', opacity: isExpired ? 0.1 : 'unset' }}
              value={urlInfo.fileUrl}
              renderAs={'svg'}
              size={200}
            />
            <Typography
              variant="caption"
              color="textSecondary"
              style={{
                color: isExpired ? 'red' : 'grey',
                marginTop: 4,
                display: validUntil.length >= 8 ? 'block' : 'none',
              }}
            >
              {t('Available until') + ': ' + validUntil}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6.5}>
          <Box
            sx={{
              minHeight: 350,
            }}
            alignContent={'center'}
          >
            <IconButton
              style={{
                display: showCloseButton ? 'block' : 'none',
                position: 'absolute',
                right: 5,
                top: 5,
              }}
              onClick={() => {
                ClipboardDialogStore.close();
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box padding={2} paddingTop={5}>
              <Typography style={{ fontSize: '21px' }}>
                {t('Showing in the room (AR)')}
              </Typography>
              <Typography style={{ fontSize: '14px', paddingTop: 8 }}>
                {t(
                  'Augmented Reality is possible on tablets or smartphones which support AR. Just open the link with the device or simply scan the QR Code.'
                )}
              </Typography>
              <Box
                display="flex"
                mt={3}
                gap={3}
                flexDirection={'column'}
                alignContent={'center'}
                alignItems={'center'}
              >
                <Button
                  variant="contained"
                  onClick={copyToClipboard}
                  sx={{ width: 150, backgroundColor: 'black' }}
                >
                  {t('Copy')}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: 150,
                    backgroundColor: 'black',
                    display: showArOpenButton ? 'block' : 'none',
                  }}
                  onClick={() => {
                    void openARLink();
                  }}
                >
                  {t('Open')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ClipboardDialogAR;
