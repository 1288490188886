import type { CookieUserSetting } from '@egr/xbox/egr-gui-dialogs/cookieDialog/CookieUserSettingManager';

import bind from 'bind-decorator';
import * as React from 'react';

import { appStore } from '../../stores/AppStore';
import { dialogManager } from '../../stores/DialogManager';
import { setMatomoOptIn } from '../../utils/Telemetry';

import { getEnvVar } from '@egr/xbox/utils/ReactScriptHelper';
import { enableTracking, matomoActive } from '@egr/xbox/telemetry/MatomoUtils';
import { enableSentry } from '@egr/xbox/utils/errors/Error';

const IMPRINT_URL: string = getEnvVar('IMPRINT_URL', '');
const LEGAL_URL: string = getEnvVar('LEGAL_URL', '');

interface CookieUserSettingAppRequirements {
    //
}

class CookieUserSettingManager extends React.Component<CookieUserSettingAppRequirements, CookieUserSetting> {
    public updateState(o: Object) {
        //
    }
    constructor() {
        super({});
    }
    public initialize(setting?: CookieUserSetting): void {
        const settings = getAcceptedCookieUserSetting();
        if (settings.telemetry && !matomoActive.get()) {
            void enableTracking();
        }

        if (settings.diagnostics) {
            enableSentry(true);
        }

        this.setState(setting ?? getAcceptedCookieUserSetting());
    }

    /**
     * Will enable telemetry and show dialog in browser
     *
     * !! in mobile app the dialog is never shown and telemetry is always enabled !!
     * @param skipUnnecessaryDialog if already required === true the dialog will not be shown
     */
    @bind
    public async showDialog(skipUnnecessaryDialog: boolean = false): Promise<void> {

        let settings: CookieUserSetting = this.state;

        const cUS: CookieUserSetting = await dialogManager.showCookieDialog();

        settings = cUS;
        saveAcceptedCookieUserSetting(settings);

        const trackingShouldBeEnabled: boolean = settings.telemetry;

        setMatomoOptIn(trackingShouldBeEnabled);

        this.updateState(settings);
    }

    @bind
    public async openLegalNotice(event: React.MouseEvent<HTMLElement>): Promise<void> {
        window.open(IMPRINT_URL.replace(/\<LANGUAGE\>/, appStore.state.language));
    }

    @bind
    public async openTermsOfUse(event: React.MouseEvent<HTMLElement>): Promise<void> {
        window.open(LEGAL_URL.replace(/\<LANGUAGE\>/, appStore.state.language));
    }

    @bind
    protected restore(value: CookieUserSetting | undefined): void {
        this.updateState({
            required: value?.required === true,
            telemetry: value?.telemetry === true,
            visitorId: value?.visitorId
        });
    }

    protected getInitialState(): CookieUserSetting {
        return {
            required: false,
            telemetry: false,
            youtube: false,
            diagnostics: false
        };
    }
}

export function saveAcceptedCookieUserSetting(value: CookieUserSetting) {
    document.cookie = 'required=' + value.required + '; max-age=31536000; path=/;'; // 1 year (seconds)
    document.cookie = 'telemetry=' + value.telemetry + '; max-age=31536000; path=/;'; // 1 year (seconds)
    document.cookie = 'youtube=' + value.youtube + '; max-age=31536000; path=/;'; // 1 year (seconds)
    document.cookie = 'diagnostics=' + value.diagnostics + '; max-age=31536000; path=/;'; // 1 year (seconds)
}
export function getAcceptedCookieUserSetting() {
    return {
        required: getCookie('required') === 'true' ? true : false,
        telemetry: getCookie('telemetry') === 'true' ? true : false,
        diagnostics: getCookie('diagnostics') === 'true' ? true : false,
        youtube: getCookie('diagnostics') === 'true' ? true : false,
        visitorId: getCookie('visitorId'),
    };
}
function getCookie(cname: string) {
    const name: string = cname + '=';
    const decodedCookie: string = decodeURIComponent(document.cookie);
    const ca: Array<string> = decodedCookie.split(';');
    for (let c of ca) {
        while (c.startsWith(' ')) {
            c = c.substring(1);
        }
        if (c.startsWith(name)) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export const defaultCookieUserSettingsManager = new CookieUserSettingManager();